import React from "react";

import {Box, Typography } from "@mui/material";

const HowItWorksTab = () => {
  return (
      <Box sx={{ maxWidth: 680, textAlign: "left"}}>
        <Typography variant="body1" component="p" gutterBottom>
          {`The image search via text machine learning model works by analyzing the content of an image and matching it with the user's search terms. Here's how it works in more detail:`}
        </Typography>
        <Typography variant="body1" component="ol" gutterBottom>
          <Typography variant="body1" component="li" gutterBottom>
            {`User enters a search term: The user enters a search term or phrase into the website's search box, such as "yellow flowers".`}
          </Typography>
          <Typography variant="body1" component="li" gutterBottom>
            {`Text analysis: The search engine processes the text using natural language processing (NLP) techniques to identify the key concepts and meaning behind the user's query. For example, the search engine might identify "yellow" and "flowers" as the most important words in the query.`}
          </Typography>
          <Typography variant="body1" component="li" gutterBottom>
            {`Image feature extraction: The model analyzes a large database of images, extracting a variety of visual features such as color histograms, edge information, texture patterns, and other details. These features are then used to create a high-dimensional feature vector that represents each image in the database.`}
          </Typography>
          <Typography variant="body1" component="li" gutterBottom>
            {`Image matching: Using the feature vector for the search query, the model compares it to the feature vectors of all the images in the database, looking for the images that are most similar in visual content to the user's query. The model then returns the top matches to the user, usually ranked by relevance or similarity.`}
          </Typography>
          <Typography variant="body1" component="li" gutterBottom>
            {`Display results: The website displays the top matching images to the user, along with any relevant metadata or additional information that may be available.`}
          </Typography>
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          {`The image search via text model is trained using machine learning algorithms and techniques. The model is trained on a large dataset of images and search queries, allowing it to learn how to extract and match relevant image features based on the text input. The more data the model is trained on, the better it becomes at identifying and matching images to search queries.`}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          {`Overall, the image search via text machine learning model is a powerful tool for helping users find relevant images quickly and easily. By leveraging the power of machine learning and natural language processing, these models can provide accurate and relevant results, even for complex or ambiguous search queries.`}
        </Typography>
      </Box>
  );
};

export default HowItWorksTab;
