import React from "react";
import { Box, Container, CssBaseline, Paper, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { HomeTheme } from "../../Themes";

const Footer = () => {
  return (
    <ThemeProvider theme={HomeTheme}>
    <CssBaseline />
    <Paper
      sx={{ width: "100%", backgroundColor: "background.dark" }}
      component="footer"
      square
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            py: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="caption" color="primary">
            Tech Seeds
          </Typography>
          <Typography variant="caption" color="primary">
            Favicon by icon8
          </Typography>
        </Box>
      </Container>
    </Paper>
    </ThemeProvider>
  );
};

export default Footer;
