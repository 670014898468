import { Box } from "@mui/material";
import { styled } from "@mui/system";

interface DivProps {
  backgroundColor?: string;
  color?: string;
  opacity?: number | string;
}

export const WavyDivider = styled(Box)<DivProps>(
  ({ theme, backgroundColor, color, opacity }) => ({
    height: 200,
    backgroundColor: backgroundColor != null ? backgroundColor : "inherit",
    position: "relative",
    "&::before": {
      content: '""',
      width: "100%",
      height: "200px",
      position: "absolute",
      bottom: "-0.3%",
      left: 0,
      backgroundSize: "auto",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "23vw bottom",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 100' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 2 32.2291 4 51.5 6C104.5 14 200 32 300 49C400 66 500 83 600 76C700 69 800 39 900 22C987.526 4 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V100H1150C1100 100 1000 100 900 100C800 100 700 100 600 100C500 100 400 100 300 100C200 100 100 100 50 100H0V1.98128Z' fill='${
        color != null ? color.replace("#", "%23") : "inherit"
      }' opacity='${opacity != null ? opacity : 1}'/></svg>")`,
    },
  })
);

export const WavyDivider2 = styled(Box)<DivProps>(
  ({ theme, backgroundColor, color, opacity }) => ({
    height: "100px",
    backgroundColor: backgroundColor != null ? backgroundColor : "inherit",
    position: "relative",
    "&::before": {
      content: '""',
      width: "100%",
      height: "200px",
      position: "absolute",
      bottom: "-0.3%",
      left: 0,
      backgroundSize: "auto",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "23vw bottom",
      backgroundImage: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 100' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 2 32.2291 4 51.5 6C104.5 14 200 32 300 49C400 66 500 83 600 76C700 69 800 39 900 22C987.526 4 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V100H1150C1100 100 1000 100 900 100C800 100 700 100 600 100C500 100 400 100 300 100C200 100 100 100 50 100H0V1.98128Z' fill='${
        color != null ? color.replace("#", "%23") : "inherit"
      }' opacity='${opacity != null ? opacity : 1}'/></svg>")`,
    },
  })
);
