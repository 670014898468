import React from "react";
import {
  Typography,
  Box,
  ThemeProvider,
  CssBaseline,
  Tab,
  Container,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { HomeTheme } from "../../../../Themes";
import ImageSearchDemo from "./Demo";
import HowItWorksTab from "./HowItWorksTab";
import APITab from "./APITab";
import { WavyDivider } from "../../../Dividers";

const ImageSearchPage: React.FC = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={HomeTheme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: "background.dark" }}>
        <Container>
          <Typography
            variant="h1"
            sx={{ fontSize: 60, fontWeight: "bold", color: "white", py: 10 }}
          >
            Image Search Via Text
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mx: 5,
              textAlign: "left",
              textIndent: 50,
              color: "primary.light",
            }}
          >
            {`\tImage Search Via Text technology allows users to search for images based on the keywords in their 
          descriptions. This technology combines advanced computer vision and natural language processing 
          algorithms to match relevant images with user queries. It can recognize thousands of objects, 
          scenes, and attributes, and is capable of handling complex queries.`}
          </Typography>
        </Container>
        <WavyDivider theme={HomeTheme} color="#f8f8f8" sx={{ height: 200 }} />
        <Box sx={{ backgroundColor: "background.default" }}>
          <Container>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="fullWidth"
                >
                  <Tab label="Demo" value="1" />
                  <Tab label="How it Works" value="2" />
                  <Tab label="API" value="3" />
                </TabList>
                <TabPanel value="1">
                  <ImageSearchDemo />
                </TabPanel>
                <TabPanel
                  value="2"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <HowItWorksTab />
                </TabPanel>
                <TabPanel value="3">
                  <APITab />
                </TabPanel>
              </Box>
            </TabContext>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ImageSearchPage;
