import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`

  interface TypeBackground {
    dark: string;
    light: string;
  }
}

// dark bg #213038
// light bg #C5F0EB
// white1 bg #248 248 248
// white2 bg #255 255 255
// header 30 30 195
// main logo against dark bg 187 253 44

// fonts are integral CF for large text, Core Sans for smaller text
// montserrat similar to integral cf
// dm sans for body


const darkbg = "#112D4E";
const header = "#1e1ec3";

export const HomeTheme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      light: "#DBE2EF",
      main: "#3F72AF",
      dark: "#112D4E",
    },
    secondary: {
      main: "#377dff",
    },
    background: {
      light: "#DBE2EF",
      default: "#F9F7F7",
      dark: darkbg
    },
  },
  typography: {
    fontFamily: "Montserrat, Lato, Arial",
    fontSize: 18,
    h1: {
      color: header,
      textTransform: "uppercase",
      fontSize: "4rem",
    },
    h2: {
      textTransform: "uppercase",
    },
    h3: {

      // textTransform: "uppercase"
    },
    h4: {

      textTransform: "uppercase",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {

      textTransform: "uppercase",
      fontSize: "1.25rem",
    },
    body1: {
      fontFamily: "Lato",
      fontWeight: 400,
      lineHeight: 1.58,
      letterSpacing: "-0.003em",
    },
    body2: {
      fontWeight: 400,
      lineHeight: 1.43,
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginTop: 40,
          marginBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "15px",
          // color: "black",
          // boxShadow: "1 1 1px #000",
          // "&:hover": {
          //   color: "#000",
          //   backgroundColor: "#fff",
          //   boxShadow: "0 0 1rem #fff",
          // },
          p: 0,
        },
        outlined: {
          borderRadius: "20px",
          color: "black",
          borderColor: "black",
          "&:hover": {
            borderColor: "black",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#213038",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#213038",
        }

      }
    }
  },
}));
