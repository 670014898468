import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./Components/Navbar/index";
import Home from "./Components/Pages/Home";
import Footer from "./Components/Footer";
import NotFound from "./Components/ErrorComponents/NotFound";
import ProductAreas, { type ProductArea, type Product } from "./Data";
import { getLink } from "./Util";
import "./App.css";
import VentureStudioProgram from "./Components/Pages/VentureStudioProgram";
import Team from "./Components/Pages/Team";
import Companies from "./Components/Pages/Companies";
import Technology from "./Components/Pages/Technology";
import Media from "./Components/Pages/Media";
import { ImageSearchPage } from "./Components/Pages/ProductPages";

const App = () => {
  // TODO upgrade to create-browser-router for breadcrumbs
  // https://reactrouter.com/en/main/hooks/use-matches
  // https://reactrouter.com/en/main/routers/create-browser-router
  // https://reactrouter.com/en/main/start/tutorial

  console.log("process.env.REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT)
  console.log(process.env)
  return (
    <div className="App">
      <BrowserRouter>
        {process.env.REACT_APP_ENVIRONMENT !== "development" ? (
          <Routes>
            <Route path="/" element={<ImageSearchPage />} />
          </Routes>
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="Technology" element={<Technology />} />
              <Route path="Studio" element={<VentureStudioProgram />} />
              <Route path="Team" element={<Team />} />
              <Route path="Companies" element={<Companies />} />
              <Route path="Media" element={<Media />} />
              {ProductAreas.map((productArea: ProductArea) => (
                <Route
                  key={productArea.title}
                  path={getLink(productArea)}
                  element={productArea.pageComponent}
                />
              ))}
              {ProductAreas.map((productArea: ProductArea) =>
                productArea.products.map((product: Product) => (
                  <Route
                    key={product.title}
                    path={getLink(product)}
                    element={product.pageComponent}
                  />
                ))
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
