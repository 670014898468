import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  Paper,
  ThemeProvider,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { HomeTheme } from "../../../Themes";
import {
  ChristyProfileImage,
  JeanClaudeProfileImage,
  KathrynProfileImage,
  NaotoProfileImage,
  NobuProfileImage,
  RachelProfileImage,
  RyokoProfileImage,
  RyoProfileImage,
  ShigeProfileImage,
  YaekoProfileImage,
} from "./Assets";
// import { WavyDivider } from "../../Dividers";

interface ProfileProps {
  name: string;
  title: string;
  image: string;
  linkedin?: string;
}

const RyoKanekoProfile: ProfileProps = {
  name: "Ryo Kaneko",
  title: "Director of Innovations",
  image: RyoProfileImage,
  linkedin: "https://www.linkedin.com/in/ryo-kaneko-5a7966133/",
};

const NobuMoritaProfile: ProfileProps = {
  name: "Nobu Morita",
  title: "Director of Innovations",
  image: NobuProfileImage,
  linkedin: "https://www.linkedin.com/in/nobumorita/",
};

const CoachesAndMentors: ProfileProps[] = [
  {
    name: "Rachel Chalmers",
    title: "Program Coach",
    image: RachelProfileImage,
    linkedin: "https://www.linkedin.com/in/rachalmers/",
  },
  {
    name: "Christy Canida",
    title: "Program Coach",
    image: ChristyProfileImage,
    linkedin: "https://www.linkedin.com/in/christycanida/",
  },
  {
    name: "Jean-Claude Junqua",
    title: "Startup Advisor",
    image: JeanClaudeProfileImage,
    linkedin: "https://www.linkedin.com/in/jeanclaudejunqua/",
  },
  {
    name: "Kathryn Segovia",
    title: "Design Thinking Expert",
    image: KathrynProfileImage,
    linkedin: "https://dschool.stanford.edu/team-members/2016/12/12/kathryn-segovia",
  },
  RyoKanekoProfile,
  NobuMoritaProfile,
];

const NECXTeam: ProfileProps[] = [
  {
    name: "Shige Ihara",
    title: "President & CEO",
    image: ShigeProfileImage,
    linkedin: "https://www.linkedin.com/in/shige-ihara-0a1b2a1/",
  },
  NobuMoritaProfile,
  RyoKanekoProfile,
  {
    name: "Ryoko Hirano",
    title: "Director of Market and Business Acceleration",
    image: RyokoProfileImage,
    linkedin: "https://www.linkedin.com/in/ryoko-hirano-0a1b2a1/",
  },
  {
    name: "Naoto Mizuguchi",
    title: "Director, Strategic Planning and Business Development",
    image: NaotoProfileImage,
    linkedin: "https://www.linkedin.com/in/naoto-mizuguchi-0a1b2a1/",
  },
  {
    name: "Yaeko Kon",
    title: "Office Manager",
    image: YaekoProfileImage,
    linkedin: "https://www.linkedin.com/in/yaeko-kon-0a1b2a1/",
  },
];

const Team = () => {
  const profileCard = ({ name, title, image, linkedin }: ProfileProps) => {
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <CardMedia
          component="img"
          src={image}
          sx={{ width: "100%", alignSelf: "center" }}
        />
        <CardContent sx={{ alignItems: "left", height: "100%", display: "flex", flexDirection: "column"}}>
          <Typography variant="h6" sx={{ textAlign: "left" }}>
            {name}
          </Typography>
          <Typography variant="caption" sx={{ textAlign: "left" }}>
            {title}
          </Typography>
          <Box sx={{display: "flex", flexGrow: 1 }}>
            {linkedin != null ? (
              <IconButton href={linkedin} sx={{ p: 0, alignSelf: "flex-end" }}>
                <LinkedInIcon />
              </IconButton>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <ThemeProvider theme={HomeTheme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: "background.dark" }}>
        <Typography variant="h1" sx={{ color: "primary.main", py: 10 }}>
          Team Page
        </Typography>
        <Container>
          {/* <WavyDivider color="#f8f8f8" /> */}
          <Paper
            sx={{
              backgroundColor: "background.default",
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          ><Typography variant="h4" sx={{ pt: 5 }}>
              NEC X Team
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ width: "100%", justifyContent: "center", pt: 5 }}
            >
              {NECXTeam.map((profile: ProfileProps, index) => (
                <Grid key={index} xs={6} sm={4} lg={3} >
                  {profileCard(profile)}
                </Grid>
              ))}
            </Grid>
            <Typography variant="h4" sx={{ pt: 5 }}>
              {"Coaches & Mentors"}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ width: "100%", justifyContent: "center", pt: 5 }}
            >
              {CoachesAndMentors.map((profile: ProfileProps, index) => (
                <Grid key={index} xs={6} sm={4} lg={3} >
                  {profileCard(profile)}
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ pt: 5, mx: 6 }} />

            
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Team;
