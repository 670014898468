import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Container,
  Typography,
  ThemeProvider,
  Button,
  CircularProgress,
  //   Unstable_Grid2 as Grid,
  Box,
  createTheme,
  ImageList,
  ImageListItem,
  Dialog,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import axios from "axios";

const POPULAR_SEARCHES = [
  "Couple",
  "Baby",
  "Ring",
  "Girl",
  "Pets",
  "Cars",
  "White Dress",
  "Flowers",
  "Wedding",
  "Nature",
];

interface ImageData {
  url: string;
  width: number;
  height: number;
}

const ImageSearchDemo = () => {
  const [text, setText] = useState<string>("");
  const [imageData, setImageData] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);

  const popularSearchButtonTheme = createTheme({
    typography: {
      button: {
        textTransform: "none",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            borderRadius: "15px",
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "#333",
            },
          },
          outlined: {
            borderRadius: "20px",
            color: "black",
            borderColor: "black",
            "&:hover": {
              borderColor: "black",
            },
          },
        },
      },
    },
  });

  const fetchData = async (text: string) => {
    setLoading(true);
    const url = "https://www.rosaapi.com:3136/api3/";
    const data = { text, dataset: "1" };
    const jsonData = JSON.stringify(data);
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    try {
      const imageData: ImageData[] = [];
      const response = await axios.post(url, jsonData, { headers });
      response.data.forEach((data: { img: string; dimensions: number[] }) => {
        const imageBase64String = `data:image/png;base64,${data.img}`;
        const [height, width] = data.dimensions;
        imageData.push({ url: imageBase64String, width, height });
      });
      console.log(imageData);
      setImageData(imageData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void fetchData(text);
  }, []);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    void fetchData(text);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Box component={"form"} onSubmit={handleSubmit} width="70%">
          <TextField
            sx={{
              ".MuiOutlinedInput-root": {
                borderRadius: 5,
              },
              margin: 4,
              width: "100%",
              // width: 600,
            }}
            label="Search"
            variant="outlined"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Container sx={{ py: 2 }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          align="left"
          fontWeight="bold"
          sx={{ my: 2 }}
        >
          Popular Searches
        </Typography>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <ThemeProvider theme={popularSearchButtonTheme}>
            {POPULAR_SEARCHES.map((search, index) => (
              <Button
                key={index}
                onClick={(e) => {
                  console.log(search);
                  setText(search);
                  e.preventDefault();
                  void fetchData(search);
                }}
                variant={search === text ? "outlined" : "contained"}
                sx={{ margin: 1, minWidth: "fit-content" }}
              >
                <Typography whiteSpace="nowrap">{search}</Typography>
              </Button>
            ))}
          </ThemeProvider>
        </Container>
      </Container>

      {loading ? (
        <CircularProgress sx={{ color: "black", margin: 40, alignSelf: "center" }} />
      ) : (
        <ImageList variant="standard" gap={8} cols={4}>
          {imageData.map((imageData: ImageData, index: number) => (
            <ImageListItem id={`${index}`} key={index} onClick={() => {setSelectedImage(imageData)}}>
              <img src={imageData.url} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      <Dialog
        open={selectedImage !== null}
        onClose={() => {
          setSelectedImage(null);
        }}
        fullWidth
        maxWidth="md"
      >
        <Box 
        component= "img"
        src={selectedImage?.url}
        sx={{
          display: "flex",
        }}/>
      </Dialog>
    </Box>
  );
};

export default ImageSearchDemo;

// <Grid
//   container
//   spacing={1}
//   sx={{
//     justifyContent: "center",
//     alignItems: "left",
//     display: "flex",
//   }}
// >
//   {imageDimensions.map((position, index) => (
//     <Grid
//       key={index}
//       sx={{
//         borderRadius: 1,
//         margin: 0.5,
//         height: 220,
//         width: 220,
//         backgroundImage: "url('" + imageUrl + "')",
//         backgroundPosition: position,
//       }}
//     >
//       {/* <img src={url} loading="lazy" /> */}
//     </Grid>
//   ))}
// </Grid>

// const processFetchedImage = () => {
//     const canvas = document.createElement("canvas");
//     const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
//     // setLoading(true);
//     if (imageUrl === "") {
//     //   setLoading(false);
//       return;
//     }
//     for (let i = 0; i < 20; i++) {
//       const image = document.getElementById(`image${i}`) as HTMLImageElement;
//       const [heightDimension, widthDimension] = imageDimensions[i];
//       canvas.width = widthDimension;
//       canvas.height = heightDimension;
//       console.log(`id${i}`, canvas.width, canvas.height);
//       // const x = Math.floor(i / 2) * 224;
//       // const y = (i % 2) * 224;
//       const x = (i * 224) % 2240;
//       const y = i < 10 ? 0 : 224;
//       ctx.drawImage(
//         image,
//         x,
//         y,
//         224,
//         224,
//         0,
//         0,
//         widthDimension,
//         heightDimension
//       );

//       const pieceBase64 = canvas.toDataURL();
//       console.log(pieceBase64);
//       if (image != null) {
//         image.src = pieceBase64;
//         // console.log(image)
//       }
//     }
//     // setLoading(false);
//   };
