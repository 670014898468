import React from "react";
import {
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PublishCommand, type PublishCommandInput } from "@aws-sdk/client-sns";
import { snsClient } from "../../../../../Clients";

const APITab = () => {
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [company, setCompany] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [submissionStatus, setSubmissionStatus] = React.useState<string>("");

  const handleSubmit = (event: React.FormEvent<Element>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget as HTMLFormElement);
    const payload = {
      name: data.get("name") as string,
      email: data.get("email") as string,
      company: data.get("company") as string,
      message: data.get("message") as string,
    };
    const params: PublishCommandInput = {
      Message: `From: ${payload.name} <${payload.email}> \nCompany: ${payload.company} \nMessage: ${payload.message}`,
      TopicArn: process.env.REACT_APP_AWS_SNS_TOPIC_ARN as string,
    };
    snsClient
      .send(new PublishCommand(params))
      .then((data) => {
        console.log(data);
        setSubmissionStatus("successfully submitted!");
      })
      .catch((err) => {
        console.log(err);
        setSubmissionStatus("failed to submit. Please try again.");
      });
  };

  return (
    <Container>
      <Paper
        elevation={4}
        component={"form"}
        onSubmit={(e: React.FormEvent<Element>) => {
          handleSubmit(e);
        }}
        onError={(errors: any) => {
          console.log(errors);
        }}
        sx={{
          alignItems: "center",
          minWidth: 400,
          p: 3,
          backgroundColor: "background.default",
        }}
      >
        <Stack sx={{ minWidth: 300, px: 3, flexGrow: 1, alignItems: "center" }}>
          <Typography variant="h5" sx={{ pb: 2 }}>
            API Registration
          </Typography>
          <Typography sx={{ pb: 5 }}>
            {`If you are interested in trying out the api yourself, please fill out the contact form. We will reach out shortly.`}
          </Typography>
        </Stack>
        <Stack spacing={6} direction={{ md: "row" }}>
          <TextField
            variant="outlined"
            label="Full Name"
            name="name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
            sx={{ flexGrow: 1, width: "100%", my: 1 }}
          />
          <TextField
            required
            variant="outlined"
            label="Email"
            name="email"
            value={email}
            inputProps={{ type: "email" }}
            onError={(errors: any) => {
              console.log(errors);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            sx={{ alignSelf: "center", flexGrow: 1, width: "100%", my: 1 }}
          />
        </Stack>

        <TextField
          variant="outlined"
          label="Company"
          name="company"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCompany(e.target.value);
          }}
          value={company}
          sx={{ width: "100%", my: 1 }}
        />
        <TextField
          placeholder="Please provide a brief description of your use case."
          variant="outlined"
          label="Message"
          name="message"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setMessage(e.target.value);
          }}
          value={message}
          multiline
          rows={6}
          sx={{ width: "100%", my: 1 }}
        />
        <Button variant="contained" type="submit" sx={{ my: 1 }}>
          Submit
        </Button>
        <Typography>{submissionStatus}</Typography>
      </Paper>
    </Container>
  );
};

export default APITab;
